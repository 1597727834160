import { Menu } from '@headlessui/react'
import cn from 'classnames'
import Link from 'next/link'
import { ReactNode } from 'react'

type Props = {
  textColorClass: string
}

const GuestMobileMenu = (props: Props) => {
  const { textColorClass } = props

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button className="size-10 lg:hidden">
            <div className={cn('relative h-[30px] w-[30px] focus:outline-none', textColorClass)}>
              <div className="absolute left-1/2 top-1/2 block w-8 -translate-x-1/2 -translate-y-1/2 scale-75 transform">
                <span
                  aria-hidden="true"
                  className={cn(
                    'absolute block h-[1px] w-8 transform bg-current transition duration-300 ease-in-out',
                    open ? 'rotate-45' : '-translate-y-2.5',
                  )}
                />
                <span
                  aria-hidden="true"
                  className={cn(
                    'absolute block h-[1px] w-8 transform bg-current transition duration-300 ease-in-out',
                    open ? 'opacity-0' : '',
                  )}
                />
                <span
                  aria-hidden="true"
                  className={cn(
                    'absolute block h-[1px] w-8 transform bg-current transition duration-300 ease-in-out',
                    open ? '-rotate-45' : 'translate-y-2.5',
                  )}
                />
              </div>
            </div>
          </Menu.Button>

          <Menu.Items
            className={cn(
              'absolute right-7 top-20 flex w-[calc(100%-56px)] flex-col justify-between overflow-hidden rounded-[20px] ',
              'border border-black/10 bg-white/80 normal-case !text-black shadow-cohart-sm backdrop-blur-[15px] md:right-10 md:w-[300px] lg:top-24',
              'z-10',
            )}
          >
            <SectionWrapper>
              <StyledMenuItem>
                <Link
                  href="/auth/login"
                  className="inline-block w-full rounded-full border border-black/10 bg-white py-3 text-center text-xs font-semibold"
                >
                  Login
                </Link>
              </StyledMenuItem>
              <StyledMenuItem>
                <Link
                  href="/auth/sign-up"
                  className="inline-block w-full rounded-full border border-black/10 bg-mint-green py-3 text-center text-xs font-semibold"
                >
                  Join
                </Link>
              </StyledMenuItem>
            </SectionWrapper>
          </Menu.Items>
        </>
      )}
    </Menu>
  )
}

const SectionWrapper = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div className={cn('flex w-full flex-col gap-4 border-b border-black/10 py-5 lg:py-6', className)}>{children}</div>
  )
}

const StyledMenuItem = (props: {
  children: ReactNode
  noPadding?: boolean
  active?: boolean
  uppercase?: boolean
  className?: string
}) => {
  const { children, noPadding, active, uppercase, className } = props
  return (
    <Menu.Item
      as="div"
      className={cn(noPadding ? 'px-0' : 'px-[30px]', active && 'font-bold', uppercase && 'uppercase', className)}
    >
      {children}
    </Menu.Item>
  )
}

export default GuestMobileMenu
